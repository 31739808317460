<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <div class="border rounded p-4 mb-3">
                <b-row>
                    <b-col sm="4"  v-for="(item,key) in dynamicFields" :key="key">
                        <ValidationProvider :name="'name'+key" v-slot="{ errors }">
                            <b-form-group :label="item.title">
                                <b-form-input type="number" v-model="item.required_score" :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                        <ValidationProvider name="graduation_ects_credit" v-slot="{ errors }">
                            <b-form-group :label="$t('graduation_ects_credit')">
                                <b-form-input type="number" v-model="formData.graduation_ects_credit" :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                        <ValidationProvider name="graduation_credit" v-slot="{ errors }">
                            <b-form-group :label="$t('graduation_credit')">
                                <b-form-input type="number" v-model="formData.graduation_credit" :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>
                <!--<b-row>
                    <b-col sm="4">
                        <ValidationProvider name="graduation_credit"
                                            v-slot="{ errors }">
                            <b-form-group :label="$t('graduation_credit')">
                                <b-form-input type="number" v-model="formData.graduation_credit"
                                              :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="graduation_ects_credit"
                                            v-slot="{ errors }">
                            <b-form-group :label="$t('graduation_ects_credit')">
                                <b-form-input type="number" v-model="formData.graduation_ects_credit"
                                              :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="graduation_dep"
                                            v-slot="{ errors }">
                            <b-form-group :label="$t('graduation_dep')">
                                <b-form-input type="number" v-model="formData.graduation_dep"
                                              :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="dep_credit"
                                            v-slot="{ errors }">
                            <b-form-group :label="$t('dep_credit')">
                                <b-form-input type="number" v-model="formData.dep_credit"
                                              :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                        <ValidationProvider name="dep_ects_credit"
                                            v-slot="{ errors }">
                            <b-form-group :label="$t('dep_ects_credit')">
                                <b-form-input type="number" v-model="formData.dep_ects_credit"
                                              :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="graduation_non_dep"
                                            v-slot="{ errors }">
                            <b-form-group :label="$t('graduation_non_dep')">
                                <b-form-input type="number" v-model="formData.graduation_non_dep"
                                              :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="non_dep_credit"
                                            v-slot="{ errors }">
                            <b-form-group :label="$t('non_dep_credit')">
                                <b-form-input type="number" v-model="formData.non_dep_credit"
                                              :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="non_dep_ects_credit"
                                            v-slot="{ errors }">
                            <b-form-group :label="$t('non_dep_ects_credit')">
                                <b-form-input type="number" v-model="formData.non_dep_ects_credit"
                                              :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                        <ValidationProvider name="graduation_general"
                                            v-slot="{ errors }">
                            <b-form-group :label="$t('graduation_general')">
                                <b-form-input type="number" v-model="formData.graduation_general"
                                              :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="general_credit"
                                            v-slot="{ errors }">
                            <b-form-group :label="$t('general_credit')">
                                <b-form-input type="number" v-model="formData.general_credit"
                                              :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="general_ects_credit"
                                            v-slot="{ errors }">
                            <b-form-group :label="$t('general_ects_credit')">
                                <b-form-input type="number" v-model="formData.general_ects_credit"
                                              :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row> -->
            </div>
            <div class="d-flex justify-content-center">
                <b-button variant="primary" @click="updateForm">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Components

// Services
import StudentProgramService from '@/services/StudentProgramService'

// Other
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
    name: "StudentsUniversityInformationUpdate",
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            formId: null,
            formData: {},
            formLoading: false,
            advisorObject: {},
            dynamicFields: []
        }
    },
    props: {
        studentProgram: {
            type: Object,
            default: null
        }
    },
    methods: {
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.formId) {
                let required_credits = {}
                let required_ects_credits = {}
                let required_count = {}
                this.dynamicFields.forEach(item => {
                    if(item.group == 'required_credits'){
                        required_credits[item.code] = item.required_score
                    } else if(item.group == 'required_ects_credits'){
                        required_ects_credits[item.code] = item.required_score
                    } else if(item.group == 'required_count'){
                        required_count[item.code] = item.required_score
                    }
                })
                let formData = {
                    graduation_ects_credit: this.formData.graduation_ects_credit,
                    graduation_credit: this.formData.graduation_credit,
                    required_credits: required_credits,
                    required_ects_credits: required_ects_credits,
                    required_count: required_count
                }

                StudentProgramService.updateGraduateRequirementInfo(this.formId, formData)
                    .then(response => {
                        this.$emit('updateFormSuccess', true);
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate)
                    })
            }
        },

        createDynamicFields() {
            Object.keys(this.studentProgram.earned_credits).forEach(index => {
                this.dynamicFields.push({
                    code: index,
                    required_score: this.studentProgram.required_credits ? this.studentProgram.required_credits[index] : 0,
                    earned_score: this.studentProgram.earned_credits[index] ? this.studentProgram.earned_credits[index] : 0,
                    title: this.studentProgram.slot_types[index] ? this.getLocaleText(this.studentProgram.slot_types[index], 'name')+' '+this.$t('credit') : 0,
                    group: "required_credits"
                })
            })
            Object.keys(this.studentProgram.earned_ects_credits).forEach(index => {
                this.dynamicFields.push({
                    code: index,
                    required_score: this.studentProgram.required_ects_credits ? this.studentProgram.required_ects_credits[index] : 0,
                    earned_score: this.studentProgram.earned_ects_credits[index] ? this.studentProgram.earned_ects_credits[index] : 0,
                    title: this.studentProgram.slot_types[index] ? this.getLocaleText(this.studentProgram.slot_types[index], 'name')+' '+this.$t('ects')+' '+this.$t('credit') : 0,
                    group: "required_ects_credits"
                })
            })
            Object.keys(this.studentProgram.earned_count).forEach(index => {
                this.dynamicFields.push({
                    code: index,
                    required_score: this.studentProgram.required_count ? this.studentProgram.required_count[index] : 0,
                    earned_score: this.studentProgram.earned_count[index] ? this.studentProgram.earned_count[index] : 0,
                    title: this.studentProgram.slot_types[index] ? this.getLocaleText(this.studentProgram.slot_types[index], 'name')+' '+this.$t('count_of') : 0,
                    group: "required_count"
                })
            })
        },
    },
    created() {
        this.createDynamicFields()
        this.formId = this.studentProgram.id;
        this.formData = {
            graduation_ects_credit: this.studentProgram.graduation_ects_credit,
            graduation_credit: this.studentProgram.graduation_credit,
        }

    }
}
</script>

