<template>
	<div>
		<multiselect
			:placeholder="$t('select')"
			v-model="selected"
			:options="options"
			:class="validateError != '' ? 'box-border-color' : ''"
			@input="handleInput($event)"
			label="text"
			track-by="value"
			:close-on-select="true"
			:clear-on-select="true"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
		>
			<span slot="noOptions">{{ $t("no_options") }}</span>
			<span slot="noResult">{{ $t("no_result") }}</span>
		</multiselect>
		<span class="invalid-feedback-custom"
		      v-show="validateError"
		      v-html="validateError">
        </span>
	</div>
</template>
<script>
	// Helpers
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";

	// Services
	import ParameterService from "@/services/ParameterService";

	// Other
	import qs from "qs";

	export default {
		props: {
			code: {
				type: String
			},
			value: {
				default: null
			},
			validateError: {
				type: String,
				default: ''
			},
			sort: {
				type: String,
				default: "name",
			},
		},
		watch: {
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options)
			}
		},
		data() {
			return {
				selected: null,
				options: []
			}
		},
		created() {
			this.getOptions();
		},
		methods: {
			handleInput: handleInput,
			setSelected: setSelected,
			getOptions() {
				const config = {
					params: {
						sort: this.sort,
						limit: -1,
					},
					paramsSerializer: (params) => qs.stringify(params, {encode: false}),
				};

				this.options = [];
				ParameterService.getItems(this.code, config)
				                .then(response => {
					                const data = response.data.data.items;
					                data.map(item => {
						                this.options.push({
							                value: item.id,
							                text: this.getLocaleText(item, 'name')
						                });
					                });
				                })
				                .then(() => {
					                this.selected = this.setSelected(this.value, this.options)
				                })
			}
		}
	}
</script>
