<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col md="4">
                    <ValidationProvider name="semester_id" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('semester')">
                            <semesters-selectbox v-model="formData.semester_id"
                                                 :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="exam_type" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('exam_type')">
                            <parameter-selectbox v-model="formData.exam_type"
                                                 code="registration_exam_types"
                                                 :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="placement_score_type" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('score_type')">
                            <parameter-selectbox v-model="formData.placement_score_type"
                                                 code="placement_score_types"
                                                 :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="placement_score" v-slot="{valid, errors}">
                        <b-form-group :label="$t('placement_score')">
                            <b-form-input v-model="formData.placement_score"
                                          :class="errors[0] ? 'is-invalid':''"
                                          type="number"
                                          min="0"
                                          max="999"
                                          step="0.00001"
                                          v-check-min-max-value
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="createForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Components
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
    import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";

    // Services
    import PointService from "@/services/PointService";

    // Other
    import {ValidationProvider, ValidationObserver} from "vee-validate"

    export default {
        components: {
            SemestersSelectbox,
            ParameterSelectbox,

            ValidationProvider,
            ValidationObserver,
        },
        props: {
            studentProgram: {
                type: Object,
                default: null,
            }
        },
        data() {
            return {
                formData: {
                    semester_id: null,
                    exam_type: null,
                    placement_score_type: null,
                    placement_score: null,
                },
                formLoading: false,
            }
        },
        methods: {
            async createForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.studentProgram.id) {
                    let formData = {
                        student_program_id: this.studentProgram.id,
                        ...this.formData
                    }
                    this.formLoading = true;
                    PointService.store(formData)
                                .then(response => {
                                    this.$toast.success(this.$t('api.' + response.data.message));
                                    this.$emit('createFormSuccess', true);
                                })
                                .catch(e => {
                                    this.showErrors(e, this.$refs.formModalValidate)
                                })
                                .finally(() => {
                                    this.formLoading = false
                                })
                }
            }
        }
    }
</script>

