<template>
	<div>
		<multiselect :placeholder="$t('select')" v-model="selected" :options="options"
			:class="validateError != '' ? 'box-border-color' : ''" @input="handleInput($event, multiple)" label="text"
			track-by="value" :multiple="multiple" :close-on-select="!multiple" :clear-on-select="!multiple"
			:select-label="''" :selected-label="''" :deselect-label="''">
			<template slot="selection" slot-scope="{ values, search, isOpen }" v-if="multiple">
				<span class="multiselect__single" v-if="values.length && !isOpen">
					{{ translateNSelected(values, 3) }}
				</span>
			</template>
			<span slot="noOptions">
				{{ $t("no_options") }}
			</span>
			<span slot="noResult">
				{{ $t("no_result") }}
			</span>
		</multiselect>
		<span class="invalid-feedback-custom" v-show="validateError" v-html="validateError" />
	</div>
</template>

<script>
// Helpers
import translateNSelected from "@/helpers/translateNSelected";
import handleInput from "@/helpers/handleInput";
import setSelected from "@/helpers/setSelected";

// Services
import RegistrationDocumentService from "@/services/RegistrationDocumentService";

import qs from "qs"

export default {
	props: {
		value: {
			default: null,
		},
		multiple: {
			default: false,
		},
		validateError: {
			type: String,
			default: "",
		}
	},
	watch: {
		value: function (newValue) {
			this.selected = this.setSelected(newValue, this.options, this.multiple)
		}
	},
	data() {
		return {
			options: [],
			selected: null,
			active: null,
		}
	},
	created() {
		this.getOptions();
	},
	methods: {
		translateNSelected: translateNSelected,
		handleInput: handleInput,
		setSelected: setSelected,
		getOptions() {
			this.options = [];
			let config = {
				params: {
					limit: "-1"
				},
				paramsSerializer: (params) => qs.stringify(params, { encode: false })
			}
			RegistrationDocumentService.getAll(config)
				.then((response) => {
					const data = response.data.data;
					data.forEach((item) => {
						this.options.push({
							value: item.id,
							text: this.getLocaleText(item, "name"),
						});
					})
				})
				.then(() => {
					this.selected = this.setSelected(this.value, this.options, this.multiple)
				})
		}
	}
}
</script>
