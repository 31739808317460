<template>
    <div>
        <h5 class="mt-1 mb-4">{{ $t('military_information').toUpper() }}</h5>
        <div class="border rounded p-4 mb-4">
            <b-row class="d-flex justify-content-center align-items-center">
                <b-col cols="12" md="4">
                    <div class="label-as-input-div">
                        <label>{{ $t('military_status') }}</label>
                        <div>
                            {{ getObjectValue(studentProgram, 'military_status_name') }}
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" md="4" v-if="studentProgram.military_status=='delayed'">
                    <div class="label-as-input-div">
                        <label>{{ $t('military_delay_date') }}</label>
                        <div>
                            {{ studentProgram.military_delay_date ? this.toLocaleDate(studentProgram.military_delay_date) : '-' }}
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" md="4">
                    <div class="label-as-input-div">
                        <label>{{ $t('military_service_city') }}</label>
                        <div>
                            {{ getObjectValue(studentProgram, 'military_service_city_code') }}
                            <span> / </span>
                            {{ getObjectValue(studentProgram, 'military_service_city_name') }}
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" md="4">
                <b-button variant="primary"
                          @click="updateFormShow"
                          v-if="checkPermission('studentprogram_updatemilitary') && editMode">
                    {{ $t('edit') }}
                </b-button>
                </b-col>

            </b-row>
        </div>


        <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                   :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                   v-show="datatable.showTable">
        </datatable>

        <CommonModal ref="formModal"
                     @bHideModalHeaderClose="clearFormData()"
                     :onHideOnlyX="true"
                     v-if="checkPermission('studentprogram_updatemilitary') && editMode">
            >
            <template v-slot:CommonModalTitle>
                {{ $t('edit').toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <update-form :studentProgram="studentProgram"
                             @updateFormSuccess="updateFormSuccess()"
                             v-if="formProcess=='update'"
                />
            </template>
        </CommonModal>
    </div>
</template>
<script>
    // Component
    import CommonModal from '@/components/elements/CommonModal';

    // Pages
    import UpdateForm from './UpdateForm';
    import qs from "qs";
    import AsalService from "@/services/AsalService";

    export default {
        components: {
            CommonModal,
            UpdateForm
        },
        props: {
            studentProgram: {
                type: Object,
                default: null
            },
            editMode: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                formProcess: null,
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: this.$t('request_type'),
                            field: this.getLocaleField('request_type_text'),
                            sortable: false,
                        },
                        {
                            label: this.$t('request_reason'),
                            field: 'request_reason_name',
                            sortable: false,
                        },
                        {
                            label: this.$t('reason_to_increase_max_duration'),
                            field: 'reason_to_increase_max_duration_name',
                            sortable: false,
                        },
                        {
                            label: this.$t('extra_time'),
                            field: 'extra_time',
                            sortable: false,
                        },

                        {
                            label: this.$t('yoksis_unit_id'),
                            field: 'yoksis_unit_id',
                            sortable: false,
                        },
                        {
                            label: this.$t('signer_name_surname'),
                            field: 'signer_name_surname',
                            sortable: false,
                        },
                        {
                            label: this.$t('created_at'),
                            field: 'created_at',
                            sortable: false,
                        },

                        {
                            label: this.$t('result'),
                            field: 'result',
                            sortable: false,
                        },

                        {
                            label: this.$t('request_result'),
                            field: 'request_result',
                            sortable: false,
                        },
                        {
                            label: this.$t('declined_reason'),
                            field: 'declined_reason',
                            sortable: false,
                        },
                        {
                            label: this.$t('delay_end_date'),
                            field: 'delay_end_date',
                            sortable: false,
                        },

                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {},
                        sort: 'id',
                        page: 1,
                        limit: 20
                    }
                }
            }

        },
        created() {
            this.datatable.queryParams.filter.national_id=this.studentProgram.national_id
            this.getRows()
        },
        methods: {
            getRows(){
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return AsalService.getAll(config)
                    .then((response) => {
                        this.datatable.rows = response.data.data
                    })
                    .finally(() => {
                        this.datatable.isLoading = false;
                    });
            },
            // Clear
            clearFormData() {
                this.formProcess = null
            },

            // Update
            updateFormShow() {
                this.formProcess = 'update'
                this.$refs.formModal.$refs.commonModal.show()
            },
            updateFormSuccess() {
                this.clearFormData();
                this.$refs.formModal.$refs.commonModal.hide()
                this.$emit('updatedData', true);
            }
        }
    }
</script>

