<template>
    <div>
        <h5 class="mt-1 mb-3">{{ $t('general_information').toUpper() }}</h5>
        <b-row>
            <b-col cols="12" lg="12">
                <b-card :header="$t('student_information')" class="soft mb-5">
                    <b-row>
                        <b-col cols="12" lg="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('student_number') }}</label>
                                <div>{{ studentProgram.student_number ? studentProgram.student_number : '-' }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('national_id') }}</label>
                                <div>{{ studentProgram.national_id ? studentProgram.national_id : '-' }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('name') }}</label>
                                <div>{{ studentProgram.name ? studentProgram.name : '-' }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('surname') }}</label>
                                <div>{{ studentProgram.surname ? studentProgram.surname : '-' }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('student_status') }}</label>
                                <div>{{ this.getLocaleText(studentProgram, 'student_status_text', '-') }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('semester_status') }}</label>
                                <div>{{ this.getLocaleText(studentProgram, 'semester_status_name', '-') }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('gno') }}</label>
                                <div>{{ studentProgram.cgpa ? studentProgram.cgpa : '-' }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('irregular') }}</label>
                                <div>{{ studentProgram.irregular ? getYesNoText(studentProgram.irregular, '-') : ''}}</div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" lg="6">
                <b-card :header="$t('university_informations')" class="soft mb-5">
                    <b-row>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('preparatory_information') }}</label>
                                <div>{{ this.getLocaleText(studentProgram, 'prep_status_name', '-') }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('affiliated_curriculum') }}</label>
                                <div>{{ getLocaleText(studentProgram,'curriculum_name','-') }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('class') }}</label>
                                <div>{{ this.getLocaleText(studentProgram, 'class_name', '-') }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('major_type') }}</label>
                                <div>{{ this.getLocaleText(studentProgram, 'major_type_name', '-') }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('completed_credit') }}</label>
                                <div>{{ studentProgram.credits ? $t('credit_x', {credit: parseInt(studentProgram.credits)}) : '-' }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('completed_akts_credit') }}</label>
                                <div>{{ studentProgram.ects_credits ? $t('credit_x', {credit: parseInt(studentProgram.ects_credits)}) : '-' }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('number_of_semesters_studied') }}</label>
                                <div>{{ studentProgram.number_of_semesters ? studentProgram.number_of_semesters : '-' }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('number_of_remaining_semesters') }}</label>
                                <div>{{ studentProgram.remaining_number_of_semesters ? studentProgram.remaining_number_of_semesters : '-' }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('yoksis_unit_id') }}</label>
                                <div>{{ this.getLocaleText(studentProgram, 'yoksis_unit_id', '-') }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('yoksis').toUpper()+' '+$t('number_of_semesters_studied') }}</label>
                                <div>{{ studentProgram.yoksis_number_of_semesters ? studentProgram.yoksis_number_of_semesters : '-' }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('yoksis').toUpper()+' '+$t('number_of_remaining_semesters') }}</label>
                                <div>{{ studentProgram.remaining_yoksis_number_of_semesters ? studentProgram.remaining_yoksis_number_of_semesters : '-' }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('prep_number_of_semesters')}}</label>
                                <div>{{ studentProgram.prep_number_of_semesters ? studentProgram.prep_number_of_semesters : '-' }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('leaving_date') }}</label>
                                <div>{{ studentProgram.leaving_date ? this.toLocaleDate(studentProgram.leaving_date) : '-' }}</div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" lg="6">
                <b-card :header="$t('registration_information')" class="soft mb-5">
                    <b-row>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('registration_date') }}</label>
                                <div>{{ studentProgram.registration_date ? this.toLocaleDate(studentProgram.registration_date) : '-' }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('registration_season') }}</label>
                                <div>
                                    {{ studentProgram.registration_semester_academic_year ? studentProgram.registration_semester_academic_year : '-' }},
                                    {{ studentProgram.registration_semester ? this.getSemester(studentProgram.registration_semester) : '-' }}
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('registration_type') }}</label>
                                <div>{{ this.getLocaleText(studentProgram, 'registration_type_text', '-') }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('registration_status') }}</label>
                                <div>{{ this.getLocaleText(studentProgram, 'registration_status', '-') }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('scholarship_rate') }}</label>
                                <div v-if="studentProgram.scholarship_rate==null">{{ $t("scholor_ship_rate_ration")}}</div>
                                <div v-else>{{"ÖSYM %"+studentProgram.scholarship_rate}}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('faculty_name') }}</label>
                                <div>{{ this.getLocaleText(studentProgram, 'faculty_name', '-') }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('department_name') }}</label>
                                <div>{{ this.getLocaleText(studentProgram, 'department_name', '-') }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('program_name') }}</label>
                                <div>{{ this.getLocaleText(studentProgram, 'program_name', '-') }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('campus') }}</label>
                                <div>{{ this.getLocaleText(studentProgram, 'campus_name', '-') }}</div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card :header="$t('other_information')" class="soft mb-5">
                    <b-row>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('advisor') }}</label>
                                <div>{{ studentProgram.advisor_name ? studentProgram.advisor_name : '-' }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('email_of_advisor') }}</label>
                                <div>{{ studentProgram.advisor_email }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('thesis_advisor') }}</label>
                                <div>{{ studentProgram.thesis_and_project_advisor ? studentProgram.thesis_and_project_advisor : '-' }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('success_status') }}</label>
                                <div>{{ this.getLocaleText(studentProgram, 'success_status_name') }}</div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    export default {
        name:"StudentGeneralInformation",
        props: {
            studentProgram: {
                type: Object,
                default: null
            }
        }
    }
</script>
