<template>
    <div>
        <h5 class="mt-1 mb-4">{{ $t('graduation_requirements').toUpper() }}</h5>
        <b-card class="soft mb-5">
            <b-row>
                <b-col cols="4" v-for="(item,key) in dynamicFields" :key="key">
                    <div class="label-as-input-div">
                        <label>{{ item.title }}</label>
                        <div>
                            {{ item.earned_score + '/' + item.required_score }}
                        </div>
                    </div>
                </b-col>
                <b-col cols="4">
                    <div class="label-as-input-div">
                        <label>{{ $t('graduation_ects_credit') }}</label>
                        <div>
                            {{ studentProgram.ects_credits + '/' + studentProgram.graduation_ects_credit }}
                        </div>
                    </div>
                </b-col>

                <b-col cols="4">
                    <div class="label-as-input-div">
                        <label>{{ $t('graduation_credit') }}</label>
                        <div>
                            {{ studentProgram.credits + '/' + studentProgram.graduation_credit }}
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <b-form-text id="input-live-help">* {{ $t('completed') + '/' + $t('must_have') }}</b-form-text>
                </b-col>
            </b-row>
        </b-card>

        <b-button variant="primary" @click="updateFormShow"
                  v-if="checkPermission('studentprogram_update') && editMode && studentProgram.can_graduation_requirement_edit == true">
            {{ $t('edit') }}
        </b-button>
        <b-button variant="primary" @click="recalculateGpa"
                  v-if="editMode && studentProgram.can_graduation_requirement_edit == false">
            {{ $t('recalculate') }}
        </b-button>

        <CommonModal ref="formModal" size="xxl"
                     :onHideOnlyX="true"
                     @bHideModalHeaderClose="clearFormData()"
                     v-if="checkPermission('studentprogram_update') && editMode">
            <template v-slot:CommonModalTitle>
                {{ $t('edit').toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <update-form :studentProgram="studentProgram"
                             @updateFormSuccess="updateFormSuccess()"
                             v-if="formProcess=='update'"
                />
            </template>
        </CommonModal>
    </div>
</template>
<script>
// Component
import CommonModal from '@/components/elements/CommonModal';
import {EventBus} from '@/main'

// Update Form
import UpdateForm from './UpdateForm';

export default {
    components: {
        CommonModal,
        UpdateForm
    },
    props: {
        studentProgram: {
            type: Object,
            default: null
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            formProcess: null,
            dynamicFields: []
        }
    },
    methods: {
        // Clear
        clearFormData() {
            this.formProcess = null
        },

        // Update
        updateFormShow() {
            this.formProcess = 'update'
            this.$refs.formModal.$refs.commonModal.show()
        },
        updateFormSuccess() {
            this.clearFormData();
            this.$refs.formModal.$refs.commonModal.hide()
            this.$emit('updatedData', true);
        },
        recalculateGpa() {
            EventBus.$emit('recalculateGpa');
        },
        createDynamicFields() {
            this.dynamicFields = []
            if (this.studentProgram.earned_credits != null) {
                Object.keys(this.studentProgram.earned_credits).forEach(index => {
                    this.dynamicFields.push({
                        code: index,
                        required_score: this.studentProgram.required_credits ? this.studentProgram.required_credits[index] : 0,
                        earned_score: this.studentProgram.earned_credits[index] ? this.studentProgram.earned_credits[index] : '-',
                        title: this.studentProgram.slot_types[index] ? this.getLocaleText(this.studentProgram.slot_types[index], 'name') + ' ' + this.$t('credit') : '-'
                    })
                })
            }
            if (this.studentProgram.earned_ects_credits != null) {
                Object.keys(this.studentProgram.earned_ects_credits).forEach(index => {
                    this.dynamicFields.push({
                        code: index,
                        required_score: this.studentProgram.required_ects_credits ? this.studentProgram.required_ects_credits[index] : 0,
                        earned_score: this.studentProgram.earned_ects_credits[index] ? this.studentProgram.earned_ects_credits[index] : '-',
                        title: this.studentProgram.slot_types[index] ? this.getLocaleText(this.studentProgram.slot_types[index], 'name') + ' ' + this.$t('ects') + ' ' + this.$t('credit') : '-'
                    })
                })
            }

            if (this.studentProgram.earned_count != null) {

                Object.keys(this.studentProgram.earned_count).forEach(index => {
                    this.dynamicFields.push({
                        code: index,
                        required_score: this.studentProgram.required_count ? this.studentProgram.required_count[index] : 0,
                        earned_score: this.studentProgram.earned_count[index] ? this.studentProgram.earned_count[index] : '-',
                        title: this.studentProgram.slot_types[index] ? this.getLocaleText(this.studentProgram.slot_types[index], 'name') + ' ' + this.$t('count_of') : '-'
                    })
                })
            }


        },
    },

    created() {
        this.createDynamicFields()
    },

    watch: {
        studentProgram: {
            handler(value) {
                this.createDynamicFields()
            }
        }
    }
}
</script>

