<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <ValidationProvider name="military_status" rules="required" v-slot="{valid, errors}">
                <b-form-group>
                    <label>{{ $t('military_status') }}</label>
                    <parameter-selectbox v-model="formData.military_status"
                                         code="military_statuses"
                                         :validate-error="errors[0]"
                    />
                </b-form-group>
            </ValidationProvider>
            <div v-if="formData.military_status=='delayed'">
                <ValidationProvider name="military_delay_date" v-slot="{valid, errors}">
                    <b-form-group :label="$t('military_delay_date')">
                        <select-date v-model="formData.military_delay_date"
                                     :validation-error="errors[0]"
                        />
                    </b-form-group>
                </ValidationProvider>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="updateForm">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Components
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
    import SelectDate from '@/components/interactive-fields/SelectDate';

    // Services
    import StudentProgramService from '@/services/StudentProgramService'

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import moment from 'moment';

    export default {
        components: {
            ParameterSelectbox,
            SelectDate,

            ValidationProvider,
            ValidationObserver
        },
        props: {
            studentProgram: {
                type: Object,
                default: null
            }
        },
        created() {
            this.formId = this.studentProgram.id;
            this.formData = {
                military_status: this.studentProgram.military_status,
                military_delay_date: this.studentProgram.military_delay_date
            }
        },
        data() {
            return {
                formId: null,
                formData: {},
                formProcess: false
            }
        },
        methods: {
            async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    let formData = {
                        'military_status': this.formData.military_status,
                        'military_delay_date': this.formData.military_delay_date ? moment(this.formData.military_delay_date).format('YYYY-MM-DD') : null
                    }
                    this.formProcess = true;
                    StudentProgramService.updateMilitary(this.formId, formData)
                                         .then(response => {
                                             this.$emit('updateFormSuccess', true);
                                             this.$toast.success(this.$t('api.' + response.data.message));
                                         })
                                         .catch((e) => {
                                             this.showErrors(e, this.$refs.formModalValidate)
                                         })
                                         .finally(() => {
                                             this.formProcess = false;
                                         })
                }
            }
        }
    }
</script>

