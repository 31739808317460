<template>
    <div>
        <page-header :title="$t('score_information').toUpper()"
                     @new-button-click="createFormShow('create')"
                     @filter-div-status="datatable.filterStatus=$event"
                     :isFilter="true"
                     :isNewButton="checkPermission('point_store') && editMode"
                     :isColumns="true">
            <template v-slot:columns>
                <div class="mb-1" v-for="(column,key) in datatable.columns">
                    <b-form-checkbox
                        :id="'checkbox-'+key"
                        :name="'checkbox-'+key"
                        :value="false"
                        :unchecked-value="true"
                        v-model="column.hidden"
                        v-if="column.field!='buttons'"
                    >
                        {{ br2space(column.label) }}
                    </b-form-checkbox>
                </div>
            </template>
        </page-header>

        <datatable-filter v-show="datatable.filterStatus" :filterButtonClass="'mt-1'" @filter="filter" @filterClear="filterClear">
            <div>
                <b-row>
                    <b-col md="6">
                        <b-form-group :label="$t('exam_type')">
                            <parameter-selectbox v-model="datatable.queryParams.filter.exam_type"
                                                 code="registration_exam_types"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
        </datatable-filter>
        <datatable :isLoading.sync="datatable.isLoading"
                   :columns="datatable.columns"
                   :rows="datatable.rows"
                   :total="datatable.total"
                   :queryParams="datatable.queryParams"
                   @on-page-change="onPageChange"
                   @on-sort-change="onSortChange"
                   @on-per-page-change="onPerPageChange"
                   v-show="datatable.showTable"
                   :edit-mode="editMode"
                   v-if="studentProgram && studentProgram.id"
        />
        <CommonModal ref="formModal"
                     size="xl"
                     :onHideOnlyX="true"
                     @bHideModalHeaderClose="clearFormData()"
        >
            <template v-slot:CommonModalTitle>
                <span v-if="formProcess=='show'">{{ $t('show').toUpper() }}</span>
                <span v-if="formProcess=='create'">{{ $t('new').toUpper() }}</span>
                <span v-if="formProcess=='update'">{{ $t('edit').toUpper() }}</span>
            </template>
            <template v-slot:CommonModalContent>
                <show-form :studentProgram="studentProgram"
                           :formId="formId"
                           v-if="formProcess=='show' && checkPermission('point_show') && editMode"
                />
                <create-form :studentProgram="studentProgram"
                             @createFormSuccess="createFormSuccess()"
                             v-if="formProcess=='create' && checkPermission('point_store') && editMode"
                />
                <update-form :studentProgram="studentProgram"
                             :formId="formId"
                             @updateFormSuccess="updateFormSuccess()"
                             v-if="formProcess=='update' && checkPermission('point_update') && editMode"
                />
            </template>
        </CommonModal>
    </div>
</template>
<script>
    // Template
    import Header from '@/layouts/AppLayout/Header'
    import PageHeader from '@/components/page/PageHeader.vue'

    // Component
    import DatatableFilter from '@/components/datatable/DatatableFilter';
    import Datatable from '@/components/datatable/Datatable';
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
    import CommonModal from '@/components/elements/CommonModal';

    // Pages
    import ShowForm from './ShowForm';
    import CreateForm from './CreateForm';
    import UpdateForm from './UpdateForm';

    // Services
    import PointService from '@/services/PointService';

    // Other
    import qs from 'qs';

    export default {
        components: {
            Header,
            PageHeader,

            DatatableFilter,
            Datatable,
            ParameterSelectbox,
            CommonModal,

            ShowForm,
            CreateForm,
            UpdateForm
        },
        props: {
            studentProgram: {
                type: Object,
                default: null
            },
            editMode: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            show: false,
                            buttons: [
                                {
                                    text: this.$t('show'),
                                    class: 'ri-search-eye-line',
                                    permission: 'point_show',
                                    callback: (row) => {
                                        this.showForm(row.id);
                                    }
                                },
                                {
                                    text: this.$t('edit'),
                                    class: 'ri-edit-box-line',
                                    permission: 'point_update',
                                    callback: (row) => {
                                        this.updateFormShow(row.id);
                                    }
                                },
                                {
                                    text: this.$t('delete'),
                                    class: 'ri-delete-bin-7-line',
                                    permission: 'point_delete',
                                    callback: (row) => {
                                        this.deleteForm(row.id)
                                    }
                                }
                            ]
                        },
                        {
                            label: this.spaceToBR(this.$t('semester')),
                            field: 'semester',
                            sortable: false,
                            thClass: 'text-center',
                            tdClass: 'text-center',
                            hidden: false,
                            formatFn: (value, row) => {
                                return row.semester ? row.semester.academic_year + ' ' + this.getLocaleText(row.semester, 'semester_text') : '-';
                            }
                        },
                        {
                            label: this.spaceToBR(this.$t('exam_type')),
                            field: 'exam_type_name',
                            sortable: false,
                            thClass: 'text-center',
                            tdClass: 'text-center',
                            hidden: false
                        },
                        {
                            label: this.spaceToBR(this.$t('score_type')),
                            field: 'placement_score_type_name',
                            sortable: false,
                            thClass: 'text-center',
                            tdClass: 'text-center',
                            hidden: false
                        },
                        {
                            label: this.spaceToBR(this.$t('placement_score')),
                            field: 'placement_score',
                            sortable: false,
                            thClass: 'text-center',
                            tdClass: 'text-center',
                            hidden: false
                        }
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {},
                        sort: 'id',
                        page: 1,
                        limit: 20
                    }
                },
                formId: null,
                formProcess: null
            }
        },
        mounted() {
            this.filterClear();
        },
        methods: {
            filter() {
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            filterClear() {
                this.datatable.queryParams.filter = {
                    student_program_id: this.studentProgram.id,
                    exam_type: null
                }
                this.filter()
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field
                this.getRows();
            },
            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return PointService.getAll(config)
                                   .then((response) => {
                                       this.datatable.rows = response.data.data
                                       this.datatable.total = response.data.pagination.total
                                   })
                                   .finally(() => {
                                       this.datatable.isLoading = false;
                                   });
            },

            // Show
            showForm(id) {
                this.formId = id
                this.formProcess = 'show'
                this.$refs.formModal.$refs.commonModal.show()
            },

            // Create
            createFormShow() {
                this.formId = null
                this.formProcess = 'create'
                this.$refs.formModal.$refs.commonModal.show()
            },
            createFormSuccess() {
                this.$refs.formModal.$refs.commonModal.hide()
                this.getRows()
                this.clearFormData()
            },

            // Update
            updateFormShow(id) {
                this.formId = id
                this.formProcess = 'update'
                this.$refs.formModal.$refs.commonModal.show()
            },
            updateFormSuccess() {
                this.$refs.formModal.$refs.commonModal.hide()
                this.getRows()
                this.clearFormData()
            },

            // Clear
            clearFormData() {
                this.formId = null
                this.formProcess = null
            },

            // Delete
            deleteForm(id) {
                this.$swal
                    .fire({
                        text: this.$t('are_you_sure_to_delete'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no')
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            PointService.del(id)
                                        .then(response => {
                                            this.$toast.success(this.$t('api.' + response.data.message));
                                            this.getRows();
                                            this.clearData();
                                        })
                                        .catch(e => {
                                            this.showErrors(e)
                                        })
                        }
                    })
            }
        }
    }
</script>

